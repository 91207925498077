<template>
  <div id="app"
       :class="[{ active: mobile }, {hide: $route.name === 'quizHub' || $route.name === 'quizGame'|| $route.name === 'stage1'|| $route.name === 'stage2'}]">
    <div id="main-main-wrapper">

      <loader v-if="getLoader"></loader>

      <transition name="fade">
        <div class="popup-wrapper" v-if="checkPopUp === true">
          <div class="popup-block">

            <p v-if="ifNo === false" class="h1-title">Чи є ви спеціалістом <br>з охорони здоров’я?</p>
            <p v-if="ifNo === true" class="h1-title h1-title-no">Вибачте, будь ласка, інформація призначена лише для
              спеціалістів охорони здоров’я</p>

            <div class="btn-wrapper" v-if="ifNo === false">
              <div class="yes" @click="closePopUp()">
                <p>Так</p>
              </div>
              <div class="no" @click="noText()">
                <p>Ні</p>
              </div>
            </div>

            <div class="btn-wrapper" v-if="ifNo === true" @click="reloadPagePopup()">
              <div class="yes">
                <p>Добре</p>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <Cookies/>


      <headerWrapper></headerWrapper>
      <router-view></router-view>
      <footerWrapper></footerWrapper>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import footerWrapper from './components/footer-wrapper'
import headerWrapper from './components/header-wrapper'
import loader from './components/loader'
import {mapActions, mapGetters} from "vuex";
import Cookies from './components/Cookies.vue'

export default {
  name: "app",

  components: {
    footerWrapper,
    headerWrapper,
    Cookies,
    loader,
    // BreadCrumbs,
  },


  data: () => ({
    checkPopUp: false,
    ifNo: false,
    mobile: false,
  }),

  computed: {
    ...mapGetters([
      'getLoader',
      'getWindowHeight',
      'getWindowWidth',
    ])
  },
  methods: {
    ...mapActions([
      'doHeightContent',
      'doAnswerData',
      'updateWindowSize',
    ]),

    closePopUp() {
      sessionStorage.setItem("popupCheck", "true")
      this.checkPopUp = false;
    },

    reloadPagePopup() {
      this.$router.go();
    },

    noText() {
      this.ifNo = true;
    },

    axiosApiUrl() {
      let URLPATH = '';
      if (location.hostname.includes('localhost')) {
        URLPATH = 'https://cbg.teva.ua'
        // URLPATH = 'https://cbg.inchkiev.ua'
      } else {
        URLPATH = window.location.origin
      }

      return URLPATH
    },

    onWindowResize() {
      this.updateWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
  },
  mounted() {

    this.$nextTick(() => {
      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);


      if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        this.mobile = true
      }
      if (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)) {
        this.mobile = true
      }

      if (isAndroid || iOS === true) {
        this.mobile = true
      }


      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);

      if (!this.mobile) {
        window.addEventListener("resize", () => {
          let vh = window.innerHeight * 0.01;

          document.documentElement.style.setProperty('--vh', `${vh}px`);

        });
      }

    });

    window.addEventListener('resize', this.onWindowResize);
  },

  created() {

    if (sessionStorage.getItem("popupCheck") === null) {
      this.checkPopUp = true;
    }

    this.$root.globalUrl = this.axiosApiUrl();

    this.doAnswerData();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
}
</script>
<style lang="scss">

@import "./assets/style/fonts.css";
@import "./assets/style/vars";

body {
  overflow-y: scroll;
}

#app {

  margin-top: var(--height_header);

  &.hide {
    margin-top: 0;
  }

  &.active {

    * {
      -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
      outline: none !important;
      cursor: none !important;
    }

  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $R;
  line-height: 1.2em !important;

}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999;
  background: rgba(53, 120, 110, 0.70);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .popup-block {
    background: white;
    max-width: 620px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width: 550px) {
      padding: 20px;
    }

    * {
      user-select: none;
    }

    .h1-title {
      color: #35786E;
      font-family: $B;
      font-size: 32px;
      text-align: center;
      @media screen and (max-width: 550px) {
        font-size: 18px;

      }
    }

    .h1-title-no {
      text-align: center;
      font-size: 28px;
    }

    .btn-wrapper {
      margin-top: 40px;
      display: flex;
      @media screen and (max-width: 550px) {
        margin-top: 20px;
      }

      .yes {
        cursor: pointer;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 50px;
        background: #35786E;

        @media screen and (max-width: 550px) {
          width: 90px;
          height: 40px;
          margin-right: 10px;
        }


        p {
          color: white;
          font-size: 24px;
          font-family: $B;
          @media screen and (max-width: 550px) {
            font-size: 16px;
          }
        }
      }

      .no {
        cursor: pointer;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 50px;
        background: #FB3449;

        @media screen and (max-width: 550px) {
          width: 90px;
          height: 40px;
          margin-right: 10px;
        }

        p {
          color: white;
          font-size: 24px;
          font-family: $B;
          @media screen and (max-width: 550px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}


#app {
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
  {
    opacity: 0;
  }
}

</style>


